// Accepts a string and length as params, and then returns a string of the specified length with "..." added to the end of the string
export default function truncate(string, maxLength) {
    if (!string) return "";
    return string.substring(0, Math.min(maxLength, string.length)) + (maxLength < string.length ? `\u2026` : "");
}

// Same functionality as the truncate() function, but splits on a word boundary
export function truncateOnBoundary(string, maxLength) {
    if (!string) return "";

    const suffix = `\u2026`;
    return string.length < maxLength
        ? string
        : `${string.substr(0, string.substr(0, maxLength - suffix.length).lastIndexOf(" "))}${suffix}`;
}
