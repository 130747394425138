import React from "react";
import BaseBreadcrumbs from "@v4/gatsby-theme-talend/src/components/breadcrumbs/Breadcrumbs";
import classNames from "classnames/bind";
import * as breadcrumbStyles from "./Breadcrumbs.module.css";

const cx = classNames.bind(breadcrumbStyles);

const Breadcrumbs = (props) => <BaseBreadcrumbs cx={cx} {...props} />;

export default Breadcrumbs;
