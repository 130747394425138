import React from "react";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import truncate from "@v4/utils/truncate";

// The crumbList prop should be an Object with the key being the path, and the value being the label
const Breadcrumbs = ({ cx, crumbList, maxLength = 40 }) => {
    const listLength = Object.keys(crumbList).length;
    let isLast = false;

    return (
        <nav aria-label="Breadcrumb" className={cx("BreadcrumbWrapper")}>
            {crumbList && (
                <ul className={cx("BreadcrumbList")}>
                    {Object.keys(crumbList).map((item, i) => {
                        // To define if the item is the last in the list of crumbs
                        if (i + 1 === listLength) isLast = true;

                        // Determine the "to" attribute, whether the item should be a Link or not
                        let to = !isLast && crumbList[item] ? crumbList[item] : false;

                        // Truncate breadcrumb text to `maxLength` with an ellipsis (&hellip;)
                        if (item.length > maxLength) item = truncate(item, maxLength);

                        return (
                            <li className={cx("BreadcrumbItem")} key={i}>
                                {i > 0 && ` `}
                                <Link className={cx("BreadcrumbLink")} to={to}>
                                    {item}
                                </Link>
                                {!isLast && <span className={cx("between")}>{`\u00bb`}</span>}
                            </li>
                        );
                    })}
                </ul>
            )}
        </nav>
    );
};

export default Breadcrumbs;
