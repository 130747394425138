import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/utils/formatContentfulRichText";
import * as MicroRichStyles from "./microRich.module.css";
const cx = classNames.bind(MicroRichStyles);

export const fragment = graphql`
    fragment microRich on ContentfulMicroRichText {
        id
        name
        content {
            raw
            references {
                ...RTEReferences
            }
        }
        cta {
            ...Cta
        }
    }
`;

// The main component
const MicroRich = ({ CTA, ...props }) => {
    const body =
        props.item.content && props.item.content.raw ? (
            <div className={cx("body")}>{renderRichText(props.item.content, formatContentfulRichText())}</div>
        ) : null;

    return (
        <div className={cx("bodyWrapper")}>
            {body}
            {props.item.cta && (
                <div className={cx(`ctaSection`)}>
                    {props.item.cta.map((cta, index) => (
                        <CTA key={`cta-${index}`} {...cta} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MicroRich;
