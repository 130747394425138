import React from "react";
import { graphql } from "gatsby";
import stylingOptions from "@v4/utils/stylingOptions";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatClassName from "@v4/utils/formatClassName";

export const fragment = graphql`
    fragment Duplex on ContentfulDuplex {
        id
        stylingOptions {
            ...stylingOptions
        }
        headingTextShade
        headingAlignment
        heading {
            raw
            references {
                ...RTEReferences
            }
        }
        bodyTextShade
        bodyAlignment
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        disableGrid
        reverseColumns
        columnSplit
        columnOne {
            __typename
            ... on Node {
                ...microRich
                ...microImage
                ...microVideo
                ...microBanner
                ...marketoForm
                ...microInfoText
                ...microInfoGraphic
            }
        }
        columnTwo {
            __typename
            ... on Node {
                ...microRich
                ...microImage
                ...microVideo
                ...microBanner
                ...marketoForm
                ...microInfoText
                ...microInfoGraphic
            }
        }
        variation
        frameBackground
    }
`;

// This component renders a grid container that represents a column (left or right), this component is also used on
// Talend resource page to show marketo forms.
export const ColumnGrid = ({ cx, disableAutoGrid, columnItems, customClass, itemType }) => {
    const colItems = columnItems
        ? columnItems.map((item, index) => {
              return (
                  <div className={cx("gridItem", customClass)} key={`item-${index}`}>
                      {itemType(item)}
                  </div>
              );
          })
        : null;

    // If 'disableAutoGrid' is truthy, then wrap the colItems in a div with class=columnGrid
    // This will let them flow naturally in each column, and not try to auto flow with the items in the other grid
    return disableAutoGrid ? <div className={cx("columnGrid")}>{colItems}</div> : colItems;
};

// The main component
export const Duplex = ({ cx, itemType, formatContentfulRichText, ...props }) => {
    // Basic Styling Options stuff
    const styleOpts = stylingOptions(props.stylingOptions, cx);

    // Set up rich-text content
    const heading = props.heading && props.heading.raw ? renderRichText(props.heading) : null;
    const body = props.body && props.body.raw ? renderRichText(props.body, formatContentfulRichText()) : null;

    // Alignment options for cx classes:
    const hAlign = props.headingAlignment ? "textAlign" + props.headingAlignment : null;
    const bAlign = props.bodyAlignment ? "textAlign" + props.bodyAlignment : null;

    // Text shade options for cx classes:
    const hShade = props.headingTextShade ? "textShade" + props.headingTextShade : null;
    const bShade = props.bodyTextShade ? "textShade" + props.bodyTextShade : null;

    // Get a boolean value for the disableGrid checkbox
    const disableAutoGrid = props.disableGrid && props.disableGrid[0] === "Yes";

    // Get a boolean value for the reverseColumns checkbox
    const reverseCols = props.reverseColumns && props.reverseColumns[0] === "Yes";

    // Column Split classes
    const [leftCol, rightCol] = (props.columnSplit ? props.columnSplit : "6/6").split("/");
    const colSplitStyles = `colSpan${leftCol}${rightCol}`;

    // set custom classnames that will add some styling for the 'autoGrid' variation as well as reversing the columns in responsive view
    const disableGridClass = !disableAutoGrid ? "auto" : "";
    const reverseColsClass = reverseCols ? "reverse" : "";

    // "Framed" variation with background and inherited styles
    const variation = props.variation ? "isFramed" : null;
    const frameBg = variation && props.frameBackground ? formatClassName("frameColor", props.frameBackground) : "";

    // Varible to hold styles pertaining to a variation of the FullWidth module
    let variationStyles = "";

    // Shared styles from the module's styling options when the variation is set to "framed"
    if (variation === "isFramed") {
        variationStyles = [
            variation,
            "frameWrapper",
            styleOpts.options?.bgColor,
            styleOpts.options?.bgGradient,
            styleOpts.options?.spacingTop,
            styleOpts.options?.spacingBottom,
        ];
    }

    return (
        <section
            id={props.moduleId}
            className={`${cx("duplexModule", variation, frameBg)} ${styleOpts?.classString}`}
            style={variation !== "isFramed" ? styleOpts?.bgHex : null}
        >
            {styleOpts && styleOpts.borderTopEl}

            <div
                className={cx("duplexWrapper", variationStyles)}
                style={variation === "isFramed" ? styleOpts?.bgHex : null}
            >
                {(heading || body) && (
                    <div className={`${cx("copyContainer")}`}>
                        {heading && <div className={cx(`heading`, hAlign, hShade)}>{heading}</div>}
                        {body && <div className={cx(`body`, bAlign, bShade)}>{body}</div>}
                    </div>
                )}
                {(props.columnOne || props.columnTwo) && (
                    <div className={cx("duplexGrid", colSplitStyles, disableGridClass, reverseColsClass)}>
                        <ColumnGrid
                            cx={cx}
                            itemType={itemType}
                            customClass={cx("colOne")}
                            columnItems={props.columnOne}
                            disableAutoGrid={disableAutoGrid}
                        />
                        <ColumnGrid
                            cx={cx}
                            itemType={itemType}
                            customClass={cx("colTwo")}
                            columnItems={props.columnTwo}
                            disableAutoGrid={disableAutoGrid}
                        />
                    </div>
                )}
                {styleOpts && styleOpts.bgImageEl}
            </div>
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};
