import React from "react";
import classNames from "classnames/bind";
import BaseMicroInfoGraphic from "@v4/gatsby-theme-talend/src/components/modules/microInfoGraphic/MicroInfoGraphic";
import * as imageStyles from "./microInfoGraphic.module.css";

const cx = classNames.bind(imageStyles);

const MicroInfoGraphic = (props) => <BaseMicroInfoGraphic cx={cx} {...props} />;

export default MicroInfoGraphic;
