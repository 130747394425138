import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { Remarkable } from "remarkable";

export const fragment = graphql`
    fragment microInfoGraphic on ContentfulMicroInfoGraphic {
        id
        imageAltText
        name
        image {
            public_id
            width
            height
            format
            secure_url
            raw_transformation
        }
        infoText {
            infoText
        }
    }
`;

const MicroInfoGraphic = ({ imageAltText, image, infoText, cx }) => {
    // If not an SVG, cut the width by half via CSS.
    const displayWidth =
        image && image[0].format !== "svg" ? { width: `${image[0].width / 2}px`, maxWidth: "100%" } : {};

    // Markdown parser
    const md = new Remarkable({
        html: true, // Enable HTML tags in source
        xhtmlOut: true, // Use '/' to close single tags (<br />)
    });

    // The content to place in the DOM
    const markdownContent = infoText ? md.render(infoText.infoText) : "";

    const imgEl = (
        <>
            {image && image[0] && (
                <Image
                    className={cx("imageContainer")}
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={image[0]}
                    alt={imageAltText}
                    directURL={image[0].secure_url}
                    imgStyle={displayWidth}
                />
            )}
        </>
    );

    return (
        <div className={cx("microInfoGraphic")}>
            <div className={cx("topBar")}></div>
            {imgEl}
            <div className={cx("description")} dangerouslySetInnerHTML={{ __html: markdownContent }}></div>
        </div>
    );
};

export default MicroInfoGraphic;
