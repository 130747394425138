import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const fragment = graphql`
    fragment microInfoText on ContentfulMicroInfoText {
        id
        name
        content {
            raw
            references {
                ...RTEReferences
            }
        }
    }
`;

// The main component
const MicroInfoText = ({ cx, formatContentfulRichText, ...props }) => {
    const body =
        props.content && props.content.raw ? (
            <div className={cx("body")}>{renderRichText(props.content, formatContentfulRichText())}</div>
        ) : null;

    return <div className={cx("bodyWrapper")}>{body}</div>;
};

export default MicroInfoText;
