// Common or repeated strings
export const commonTranslations = {
    en: {
        pageStr: "Page",
        viewMore: "View more",
        viewLess: "View less",
        showMore: "Show more",
        showLess: "Show less",
        seeAll: "See all",
        toTop: "Return to top",
        readMore: "Read more",
        downloadNow: "Download now",
        watchNow: "Watch now",
        listenNow: "Listen now",
        relatedArticles: "Related articles",
        moreRelatedArticles: "More related articles",
    },
    "en-GB": {
        pageStr: "Page",
        viewMore: "View more",
        viewLess: "View less",
        showMore: "Show more",
        showLess: "Show less",
        seeAll: "See all",
        toTop: "Return to top",
        readMore: "Read more",
        downloadNow: "Download now",
        watchNow: "Watch now",
        listenNow: "Listen now",
        relatedArticles: "Related articles",
        moreRelatedArticles: "More related articles",
    },
    fr: {
        pageStr: "page",
        viewMore: "Voir plus",
        viewLess: "Afficher moins",
        showMore: "Afficher plus",
        showLess: "Afficher moins",
        seeAll: "Voir tout",
        toTop: "Retourner en haut",
        readMore: "En savoir plus",
        downloadNow: "Télécharger",
        watchNow: "Regarder",
        listenNow: "Écoute maintenant",
        relatedArticles: "Articles connexes",
        moreRelatedArticles: "Plus d'articles connexes",
    },
    de: {
        pageStr: "Buchseite",
        viewMore: "Mehr sehen",
        viewLess: "Weniger anzeigen",
        showMore: "Zeig mehr",
        showLess: "Zeige weniger",
        seeAll: "Alles sehen",
        toTop: "Zurück nach oben",
        readMore: "Weitere Informationen",
        downloadNow: "Jetzt herunterladen",
        watchNow: "Jetzt ansehen",
        listenNow: "Jetzt anhören",
        relatedArticles: "Artikel zu diesem Thema",
        moreRelatedArticles: "Weitere Artikel zu diesem Thema",
    },
    it: {
        pageStr: "Pagina",
        viewMore: "Visualizza di più",
        viewLess: "Mostra meno",
        showMore: "Mostra di più",
        showLess: "Mostra meno",
        seeAll: "Vedi tutti.",
        toTop: "Ritorna su",
        readMore: "Leggi di più",
        downloadNow: "Scarica subito",
        listenNow: "Ascolta ora",
        watchNow: "Guarda ora",
        relatedArticles: "Articoli correlati",
        moreRelatedArticles: "Altri articoli correlati",
    },
    ja: {
        pageStr: "ページ",
        viewMore: "もっと見る",
        viewLess: "少なく表示する",
        showMore: "もっと見せる",
        showLess: "遅くに",
        seeAll: "すべてを見る",
        toTop: "トップに戻る",
        readMore: "さらに読む",
        downloadNow: "今すぐダウンロード",
        watchNow: "今すぐ見る",
        listenNow: "今すぐ聞く",
        relatedArticles: "関連記事",
        moreRelatedArticles: "追加の関連記事",
    },
};
