import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const resourcePageTranslations = {
    en: {
        related: common.en.relatedArticles,
        knowledgeCenter: "Resource center",
    },
    "en-GB": {
        related: common["en-GB"].relatedArticles,
        knowledgeCenter: "Resource centre",
    },
    fr: {
        related: common.fr.relatedArticles,
        knowledgeCenter: "Centre de ressources",
    },
    de: {
        related: common.de.relatedArticles,
        knowledgeCenter: "Ressourcencenter",
    },
    it: {
        related: common.it.relatedArticles,
        knowledgeCenter: "Centro Risorse",
    },
    ja: {
        related: common.ja.relatedArticles,
        knowledgeCenter: "リソースセンター",
    },
};
