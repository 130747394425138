// extracted by mini-css-extract-plugin
export var auto = "duplex-module--auto--cba0a";
export var colOne = "duplex-module--colOne--9bf59";
export var colSpan48 = "duplex-module--colSpan48--6982e";
export var colSpan57 = "duplex-module--colSpan57--1cb57";
export var colSpan66 = "duplex-module--colSpan66--f916b";
export var colSpan75 = "duplex-module--colSpan75--71a31";
export var colSpan84 = "duplex-module--colSpan84--b99ce";
export var colTwo = "duplex-module--colTwo--8aec0";
export var columnGrid = "duplex-module--columnGrid--038d4";
export var copyContainer = "duplex-module--copyContainer--58835";
export var duplexGrid = "duplex-module--duplexGrid--8bc46";
export var duplexModule = "duplex-module--duplexModule--70c92";
export var duplexWrapper = "duplex-module--duplexWrapper--f394e";
export var frameColorWhite = "duplex-module--frameColorWhite--89fd0";
export var frameWrapper = "duplex-module--frameWrapper--a3417";
export var heading = "duplex-module--heading--bc212";
export var isFramed = "duplex-module--isFramed--1c2e5";
export var reverse = "duplex-module--reverse--03488";