import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout/Layout";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { ColumnGrid } from "@v4/gatsby-theme-talend/src/components/modules/duplex/Duplex";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { resourcePageTranslations } from "../../translations/resourcePage";
import { itemType } from "../../components/modules/duplex/Duplex";
import classNames from "classnames/bind";
const cx = classNames.bind({});

export const query = graphql`
    query($id: String!, $locale: String!, $language: String!, $resourceId: String, $topics: [String]) {
        contentfulResourcePage(id: { eq: $id }) {
            id
            slug
            node_locale
            languages
            title
            metaFields {
                ...meta
            }
            subheading {
                raw
                references {
                    ...RTEReferences
                }
            }
            content {
                raw
                references {
                    ...RTEReferences
                }
            }
            form {
                __typename
                ... on Node {
                    ...marketoForm
                }
            }
            resource {
                resourceTopic {
                    name
                }
            }
        }
        relatedContent: allContentfulResource(
            sort: { fields: createdAt, order: DESC }
            filter: {
                resourceTopic: { elemMatch: { name: { in: $topics } } }
                node_locale: { eq: $locale }
                id: { ne: $resourceId }
                languages: { in: [$language, "All"] }
            }
        ) {
            nodes {
                id
                node_locale
                title
                pageLink {
                    ...PageLink
                }
                url
            }
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function ResourcePage({ data, pageContext }) {
    // Shortcut
    const resourcePage = data.contentfulResourcePage;
    const { locale } = pageContext;

    // Data for use with footer components
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    // Meta values for the resource page.
    const metaValues = resourcePage.metaFields ?? {};

    // Translations.
    const translations = resourcePageTranslations[locale];

    const topic = resourcePage?.resource?.[0]?.resourceTopic?.[0]?.name;

    // Set up breadcrumbs object
    const crumbList = {
        [translations.knowledgeCenter]: "/resources/",
        [topic]: null,
        [resourcePage.title]: null,
    };

    // Related resources.
    const RelatedContent = () => (
        <div className="container px-2 md:py-5 lg:py-10">
            <div className="p-3 mb-2 py-5  relative z-10 md:border md:border-c_light_grey md:px-8 md:shadow-lg desktop:px-16">
                <h4>{translations.related}</h4>
                <ul className="md:grid md:grid-cols-2 md:gap-3">
                    {data.relatedContent?.nodes?.map(({ id, pageLink, url, title, node_locale }) => {
                        // Determine resource link
                        const slug = url || (pageLink && pageLink.slug) || null;
                        return (
                            <li key={id} className="ml-0 list-none mb-2 md:mb-0">
                                <Link
                                    to={getLangPrefixedPath(slug, node_locale)}
                                    className="hover:text-c_primary hover:underline text-base"
                                    language={node_locale}
                                >
                                    {title}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );

    return (
        <Layout headerMenu={data.contentfulMenu} footerMenu={footerData} pageStyleProps={{}}>
            <SEO
                title={metaValues.metaTitle || resourcePage.title}
                pathname={resourcePage.slug}
                description={metaValues.metaDescription}
                canonicalUrl={metaValues.canonicalUrl}
                canonicalUrlOverride={metaValues.canonicalUrlOverride}
                robots={metaValues.metaRobot}
                bingBot={metaValues.bingBot}
                googleBot={metaValues.googleBot}
                metaImage={metaValues?.metaImage ?? null}
                modifiedTime={resourcePage.updatedAt}
                createdTime={resourcePage.createdAt}
                languages={resourcePage.languages}
                type={`Article`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <article>
                <section className="pt-8 bg-gradient-to-t from-c_grad_seo_resources-end to-c_grad_seo_resources-start">
                    <div className="container px-2 md:px-4">
                        <h1 className="mb-2 text-4xl md:text-h1">{resourcePage.title}</h1>
                        <div className="mb-5">
                            {resourcePage.subheading?.raw &&
                                renderRichText(resourcePage.subheading, formatContentfulRichText())}
                        </div>
                        <Breadcrumbs crumbList={crumbList} />
                    </div>
                </section>

                <section
                    className={`container px-2 text-c_slate md:px-4 ${
                        resourcePage.form ? "grid desktop:grid-cols-4 lg:grid-cols-12 gap-8 lg:gap-0" : ""
                    }`}
                >
                    {resourcePage.form && (
                        <div className="max-w-2xl mx-auto mb-6 desktop:col-start-3 desktop:col-span-2 lg:col-span-5 lg:col-start-8 order-2 device:order-1">
                            <ColumnGrid
                                cx={cx}
                                columnItems={[resourcePage.form]}
                                itemType={itemType}
                                disableAutoGrid={false}
                            />
                        </div>
                    )}
                    {resourcePage.content && (
                        <div
                            className={
                                resourcePage.form
                                    ? "row-start-1 desktop:col-span-2 lg:col-span-6 order-1 device:order-2"
                                    : ""
                            }
                        >
                            {resourcePage.content?.raw &&
                                renderRichText(resourcePage.content, formatContentfulRichText())}
                        </div>
                    )}
                </section>
                {data.relatedContent?.nodes?.length > 0 ? <RelatedContent /> : null}
            </article>
        </Layout>
    );
}
