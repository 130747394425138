import React from "react";
import classNames from "classnames/bind";
import formatContentfulRichText from "../../../utils/formatContentfulRichText";
import BaseMicroInfoText from "@v4/gatsby-theme-talend/src/components/modules/microInfoText/MicroInfoText";
import * as MicroInfoTextStyles from "./microInfoText.module.css";
const cx = classNames.bind(MicroInfoTextStyles);

// The main component
const MicroInfoText = (props) => (
    <BaseMicroInfoText cx={cx} formatContentfulRichText={formatContentfulRichText} {...props} />
);

export default MicroInfoText;
